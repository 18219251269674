import {
  type ClientBrowserParameters,
  type ShopifyAddToCartPayload,
  type ShopifyAnalytics,
  type ShopifyPageViewPayload,
  AnalyticsEventName as ShopifyAnalyticsEventName,
  getClientBrowserParameters,
  sendShopifyAnalytics,
} from '@shopify/hydrogen-react'
import { customAlphabet } from 'nanoid'

import { type CurrencyCode } from '@data/shopify/storefront/types'
import { getRandomString } from './helpers'

interface AnalyticsSettings {
  googleEvents: boolean
  shopifyEvents: boolean
}

export enum AnalyticsEventType {
  GoogleTagManager = 'Google Tag Manager',
  Shopify = 'Shopify',
}

export enum AnalyticsEventName {
  AddToCart = 'Add to cart',
  BookADemo = 'Book a demo',
  Contact = 'Contact',
  FrameBuilderOpenStep = 'Open frame step',
  FrameBuilderAddToCart = 'Add frame to cart',
  NewsletterSignUp = 'Newsletter sign up',
  // Alternative to Pageview, triggered only when loading the initial page
  PageLoad = 'Page load',
  // Alternative to Pageview, triggered only when navigating between pages using Next.js link
  PageNavigation = 'Page navigation',
  Pageview = 'Pageview',
}

export interface AnalyticsEvent {
  id: string
  type: AnalyticsEventType
  name: AnalyticsEventName | string
  payload?: Record<string, unknown>
}

/**
 * Creates custom analytics events based on event name.
 */
export const getAnalyticsEvents = (
  eventName: AnalyticsEventName | string,
  payload: Record<string, unknown>,
  settings: AnalyticsSettings
) => {
  const nanoid = customAlphabet('1234567890abcdef', 16)
  const analyticsEvents: AnalyticsEvent[] = []

  switch (eventName) {
    case AnalyticsEventName.AddToCart: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload: {
            pagePath: window.location.pathname,
            pageTitle: document.title,
            ...payload,
          },
        })
      }

      if (settings.shopifyEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.Shopify,
          name: eventName,
          payload: {
            ...getClientBrowserParameters(),
            ...payload,
          },
        })
      }

      return analyticsEvents
    }

    case AnalyticsEventName.BookADemo: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload,
        })
      }

      return analyticsEvents
    }

    case AnalyticsEventName.NewsletterSignUp: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload,
        })
      }

      return analyticsEvents
    }

    case AnalyticsEventName.Pageview: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload: {
            pagePath: window.location.pathname,
            pageTitle: document.title,
            ...payload,
          },
        })
      }

      if (settings.shopifyEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.Shopify,
          name: eventName,
          payload: {
            ...getClientBrowserParameters(),
            ...payload,
          },
        })
      }

      return analyticsEvents
    }

    case AnalyticsEventName.PageLoad: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload: {
            pagePath: window.location.pathname,
            pageTitle: document.title,
            ...payload,
          },
        })
      }

      return analyticsEvents
    }

    case AnalyticsEventName.PageNavigation: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload: {
            pagePath: window.location.pathname,
            pageTitle: document.title,
            ...payload,
          },
        })
      }

      return analyticsEvents
    }

    default: {
      if (settings.googleEvents) {
        analyticsEvents.push({
          id: nanoid(),
          type: AnalyticsEventType.GoogleTagManager,
          name: eventName,
          payload,
        })
      }

      return analyticsEvents
    }
  }
}

/**
 * Triggers an event in Google Tag Manager.
 */
export const triggerGoogleTagManagerEvent = (
  event: AnalyticsEventName | string,
  payload?: Record<string, unknown>
) => {
  let dataLayerEvent = {
    event,
    eventId: getRandomString(),
  }

  if (payload) {
    dataLayerEvent = {
      ...dataLayerEvent,
      ...payload,
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataLayerEvent)
}

/**
 * Triggers an event in Shopify.
 */
export const triggerShopifyEvent = async (
  eventName: AnalyticsEventName | string,
  clientBrowserParameters: ClientBrowserParameters,
  shopId?: string | null,
  currency?: CurrencyCode,
  cartId?: string,
  shopDomain?: string
) => {
  switch (eventName) {
    case AnalyticsEventName.Pageview: {
      if (!shopId || !currency) {
        break
      }

      const payload: ShopifyPageViewPayload = {
        ...clientBrowserParameters,
        hasUserConsent: true,
        shopId,
        currency,
      }

      const event: ShopifyAnalytics = {
        eventName: ShopifyAnalyticsEventName.PAGE_VIEW,
        payload,
      }
      await sendShopifyAnalytics(event, shopDomain)
      break
    }

    case AnalyticsEventName.AddToCart: {
      if (!shopId || !currency || !cartId) {
        break
      }

      const payload: ShopifyAddToCartPayload = {
        ...clientBrowserParameters,
        hasUserConsent: true,
        shopId,
        currency,
        cartId,
      }

      const event: ShopifyAnalytics = {
        eventName: ShopifyAnalyticsEventName.ADD_TO_CART,
        payload,
      }
      await sendShopifyAnalytics(event, shopDomain)
      break
    }
  }
}
