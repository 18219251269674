import {
  getShopAndCartQuery,
  getShopQuery,
} from '@data/shopify/storefront/queries/common'
import {
  type CartFragmentFragment,
  type GetShopAndCartQuery,
  type GetShopAndCartQueryVariables,
  type GetShopQuery,
  type ShopFragmentFragment,
} from '@data/shopify/storefront/types'
import { type Locale } from '@lib/language'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { type ShopifyClient, queryShopifyStorefront } from './client'

type ShopifyShopAndCart = [
  ShopFragmentFragment | undefined,
  CartFragmentFragment | undefined
]

/**
 * Gets Shopify shop information.
 */
const getShopifyShop = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient
) => {
  const getShopResult = await queryShopifyStorefront<GetShopQuery>(
    locale,
    shopifyStorefrontGraphQlClient,
    getShopQuery
  )

  const shop = getShopResult.data?.shop ?? undefined

  return [
    shop as ShopFragmentFragment | undefined,
    undefined,
  ] as ShopifyShopAndCart
}

/**
 * Gets Shopify shop information and cart.
 */
export const getShopifyShopAndCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient
) => {
  // Read cart ID from local storage
  const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
  const cartId = localStorage.getItem(shopifyCartIdStorageKey)

  if (!cartId) {
    return await getShopifyShop(locale, shopifyStorefrontGraphQlClient)
  }

  const getShopAndCartResult = await queryShopifyStorefront<
    GetShopAndCartQuery,
    GetShopAndCartQueryVariables
  >(locale, shopifyStorefrontGraphQlClient, getShopAndCartQuery, {
    cartId,
  })

  const shop = getShopAndCartResult.data?.shop ?? undefined
  const cart = getShopAndCartResult.data?.cart ?? undefined

  return [
    shop as ShopFragmentFragment | undefined,
    cart as CartFragmentFragment | undefined,
  ] as ShopifyShopAndCart
}
