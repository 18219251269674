import { CustomerErrorCode } from '@data/shopify/storefront/types'
import { type ParseResults, parseResultsOk } from '@lib/request'

interface ShopifyUserError {
  code?: string | null
  field?: string[] | null
  message: string
}

/**
 * Gets field name from Shopify GraphQL API result error.
 */
export const getGrqphQlErrorFieldName = (errorField?: string[] | null) => {
  const count = errorField?.length ?? 0

  if (errorField && count > 0) {
    return errorField[count - 1]
  }
}

/**
 * Gets validation results from Shopify user errors.
 */
export const parseUserErrors = (userErrors?: ShopifyUserError[]) => {
  const results: ParseResults = {
    ...parseResultsOk,
    errorCount: userErrors?.length ?? 0,
  }

  userErrors?.forEach((userError, index) => {
    const fieldName = getGrqphQlErrorFieldName(userError.field)
    results.fieldErrors[fieldName ?? index] = userError.message

    if (userError.code === CustomerErrorCode.UnidentifiedCustomer) {
      results.status = 'invalid_credentials'
    }
  })

  return results
}

/**
 * Gets an error message from Shopify user error list.
 */
export const getUserErrorMessage = (userErrors?: ShopifyUserError[]) => {
  const errors = userErrors ?? []

  if (errors.length === 0) {
    return
  }

  return errors
    .map((error) =>
      !error.field
        ? error.message
        : `[${error.field.join('.')}] ${error.message}`
    )
    .join('; ')
}
