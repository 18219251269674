import { type SanityStrings } from '@data/sanity/queries/types/site'
import { type CartFragmentFragment } from '@data/shopify/storefront/types'
import { type DiscountItem } from '@lib/discount/types'
import { type Locale } from '@lib/language'
import { getShopifyCartIdStorageKey } from '@lib/local-storage'
import { parseShopifyCart } from '@lib/shopify/cart'
import {
  createShopifyCart,
  updateShopifyCartBuyerIdentity,
} from '@lib/shopify/graphql/cart'
import { type ShopifyClient } from '@lib/shopify/graphql/client'
import { validateVatId } from './request'
import {
  type Cart,
  type CartFormValues,
  type CartTotals,
  type CartValidationResult,
} from './types'

export const emptyCart: Cart = {
  id: '',
  lineItems: [],
  prices: {
    subtotal: 0,
  },
  webUrl: '',
}

/**
 * Validates cart form.
 */
export const validateCart = async (
  strings: SanityStrings,
  values: CartFormValues
) => {
  const result: CartValidationResult = {
    errors: {},
    vatIdCountryCode: null,
  }

  if (values.vatId) {
    try {
      const validationResult = await validateVatId(values.vatId)
      result.vatIdCountryCode = validationResult.countryCode ?? null

      if (!validationResult.isValid) {
        result.errors.vatId = strings.cartInvalidVatIdError
      }
    } catch {
      result.errors.vatId = strings.cartVatIdError
    }
  }

  return result
}

/**
 * Gets or creates Shopify cart.
 */
export const getOrCreateCart = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cart?: CartFragmentFragment
) => {
  if (cart) {
    const currentCart = await parseShopifyCart(locale, cart)

    if (currentCart) {
      return currentCart
    }
  }

  // Delete saved cart
  if (typeof window !== 'undefined') {
    const shopifyCartIdStorageKey = getShopifyCartIdStorageKey(locale)
    localStorage.removeItem(shopifyCartIdStorageKey)
  }

  // Create a new cart
  try {
    const newCart = await createShopifyCart(
      locale,
      shopifyStorefrontGraphQlClient,
      {
        note: '',
      }
    )
    const newParsedCart = await parseShopifyCart(locale, newCart)

    return newParsedCart
  } catch (error) {
    console.log(error)
  }
}

/**
 * Sets buyer identity for a Shopify cart.
 */
export const setCartBuyerIdentity = async (
  locale: Locale,
  shopifyStorefrontGraphQlClient: ShopifyClient,
  cartId: string,
  customerAccessToken: string
) => {
  try {
    const newCart = await updateShopifyCartBuyerIdentity(
      locale,
      shopifyStorefrontGraphQlClient,
      cartId,
      {
        customerAccessToken,
      }
    )
    const newParsedCart = await parseShopifyCart(locale, newCart)

    return newParsedCart
  } catch (error) {
    console.log(error)
  }
}

/**
 * Returns prices of cart items.
 */
export const getCartPrices = (
  cart: Cart,
  cartDiscountItems: DiscountItem[]
) => {
  // Discounts
  const lineItemDiscount = cartDiscountItems.reduce(
    (total, cartDiscountItem) => {
      const quantity = cartDiscountItem.quantity ?? 1
      return total + cartDiscountItem.amount * quantity
    },
    0
  )

  // Total price
  const total = [
    cart.prices.subtotal,
    cart.prices.shipping ?? 0,
    -(cart.prices.automaticDiscount ?? 0),
    -lineItemDiscount,
  ].reduce((total, price) => total + price, 0)

  const cartTotals: CartTotals = {
    subtotal: cart.prices.subtotal,
    automaticDiscount: cart.prices.automaticDiscount ?? 0,
    lineItemDiscount,
    shipping: cart.prices.shipping ?? 0,
    total,
  }
  return cartTotals
}

/**
 * Gets cart line item count.
 */
export const getCartItemCount = (cart: Cart) =>
  cart.lineItems.reduce((total, lineItem) => total + lineItem.quantity, 0)
